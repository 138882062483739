import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router/index.js";

export default {
  namespaced: true,
  state: {
    doctors: [],
    errors: {},
    pagination: {
      currentPage: 1,
      total: 1,
    },
    doctorsCount: 0,
    search: "",
    currentDoctor: {
      first_name: "",
      last_name: "",
      pwz: "",
      main_medical_category_id: null,
      curator_id: null,
      has_curator: false,
      email: "",
      phone_number: "",
      company_name: "",
      nip: "",
      street: "",
      house_number: "",
      flat_number: "",
      postal_code: "",
      city: "",
      account_number: "",
      free_from_vat: false,
      commission_option: null,
      commission_percent: null,
      patient_discount_percent: null,
    },
    currentDoctorStats: {
      referrals: {
        issued: 0,
        paid: 0,
        canceled: 0,
        completed: 0,
      },
      commission: 0,
    },
    statuses: [
      {
        id: 1,
        name: "active",
      },
      {
        id: 2,
        name: "inactive",
      },
      {
        id: 3,
        name: "blocked",
      },
    ],
    cities: [],
    topDoctors: [],
    medicalCategories: [],
    curatorOptions: [],
    tabReffeeralCreate: 1,
  },
  getters: {
    getDoctors: (state) => state.doctors,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentDoctor: (state) => state.currentDoctor,
    getCurrentDoctorStats: (state) => state.currentDoctorStats,
    getDoctorById: (state) => (id) => {
      return state.doctors.find((doctor) => doctor.id === id);
    },
    getDoctorsCount: (state) => state.doctorsCount,
    getStatuses: (state) => state.statuses,
    getStatusById: (state) => (id) => {
      return state.statuses.find((status) => status.id === id);
    },
    getCities: (state) => state.cities,
    getTopDoctors: (state) => state.topDoctors,
    getMedicalCategories: (state) => state.medicalCategories,
    getMedicalCategoryId: (state) => (name) =>
      state.medicalCategories.find((category) => category.value === name),
    getTabReffeeralCreate: (state) => state.tabReffeeralCreate,
    getCuratorOptions: (state) => state.curatorOptions,
  },
  mutations: {
    setDoctors(state, doctors) {
      state.doctors = doctors.data;
      state.pagination.currentPage = doctors.meta.current_page;
      state.pagination.total = doctors.meta.last_page;
    },
    setDoctorsCount(state, doctorsCount) {
      state.doctorsCount = doctorsCount;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentDoctor(state, currentDoctor) {
      state.currentDoctor = currentDoctor;
    },
    setCurrentDoctorStats(state, stats) {
      state.currentDoctorStats = stats;
    },
    setCurrentDoctorValue(state, currentDoctor) {
      Object.assign(state.currentDoctor, currentDoctor);
    },
    clearCurrentDoctor(state) {
      Object.keys(state.currentDoctor).forEach(
        (key) => (state.currentDoctor[key] = "")
      );
      state.currentDoctor.main_medical_category_id = null;
      state.currentDoctor.curator_id = null;
      state.currentDoctor.has_curator = false;
      state.currentDoctor.free_from_vat = false;
      state.currentDoctor.commission_option = null;
    },
    updateDoctor(state, user) {
      const index = state.doctors.findIndex((doctor) => doctor.id === user.id);
      state.doctors[index] = user;
    },
    setCities(state, cities) {
      cities.unshift({ label: "admin.doctor.city", value: null });
      cities.push({ label: "admin.cities.all", value: null });
      state.cities = cities;
    },
    setTopDoctors(state, topDoctors) {
      state.topDoctors = topDoctors;
    },
    setMedicalCategories(state, medicalCategories) {
      medicalCategories.unshift({
        label: "list",
        value: null,
      });
      medicalCategories.push({ label: "all", value: null });
      state.medicalCategories = medicalCategories;
    },
    clearSearch(state) {
      state.search = "";
    },
    clearDoctors(state) {
      state.doctors = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
    setTabReffeeralCreate(state, tab) {
      state.tabReffeeralCreate = tab;
    },
    setCuratorsOptions(state, options) {
      state.curatorOptions = options;
    },
  },
  actions: {
    async fetchDoctors({ commit, state }, { filters, sortBy }) {
      const { data } = await http.get("admin/doctors", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...filters,
          ...sortBy,
        },
      });
      const doctors = data.doctors;
      commit("setDoctors", doctors);
      commit("setDoctorsCount", data.doctors_count);
      commit("setCities", data.cities);
      commit("setMedicalCategories", data.medical_categories);
    },
    async changeStatus(_, data) {
      return await http
        .post(`/admin/doctors/change-status/${data.id}`, {
          status: data.status,
        })
        .then(() => {
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            notify({
              type: "danger",
              text: "messages.save.failure",
              duration: 5000,
            });
        });
    },
    async saveDoctor({ commit, state }) {
      return await http
        .post(`/admin/doctors/store`, state.currentDoctor)
        .then(() => {
          commit("clearErrors");
          commit("clearCurrentDoctor");
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({ name: "AdminDoctors" });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.save.failure",
            duration: 5000,
          });
        });
    },
    async updateDoctor({ commit, state }, id) {
      await http
        .put(`/admin/doctors/update/${id}`, state.currentDoctor)
        .then(() => {
          commit("clearErrors");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
          router.push({ name: "AdminDoctors" });
          commit("clearCurrentDoctor");
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          }
          if (response && response.status === 422)
            commit("setErrors", response.data.errors);
          notify({
            type: "danger",
            text: "messages.edit.failure",
            duration: 5000,
          });
        });
    },
    async fetchCurrentDoctor(
      { commit, rootGetters },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      return await http
        .get(`admin/doctors/${id}`, {
          params: {
            page: rootGetters["referrals/getPagination"].currentPage,
            time: time,
            date_range: date_range,
            ...filters,
            ...sortBy,
          },
        })
        .then(({ data }) => {
          commit("setCurrentDoctor", data.doctor);
          commit("setCurrentDoctorStats", data.stats);
          commit("referrals/setReferrals", data.referrals, { root: true });
          commit("referrals/setCommissionRange", data.commission_range, {
            root: true,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async editDoctor({ commit }, id) {
      return await http
        .get(`admin/doctors/edit/${id}`)
        .then(({ data }) => {
          commit("setCurrentDoctor", data.doctor);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async fetchCurators({ commit }) {
      const { data } = await http.get("/curators");
      commit("setCuratorsOptions", data);
    },
  },
};
