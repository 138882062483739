import { createLogger, createStore } from "vuex";

import auth from "./modules/auth";
import billingData from "./modules/billingData";
import cart from "./modules/cart";
import doctors from "./modules/doctors";
import examinations from "./modules/examinations";
import facilities from "./modules/facilities";
import language from "./modules/language";
import languages from "./modules/languages";
import medicalCategories from "./modules/medicalCategories";
import message from "./modules/message";
import modals from "./modules/modals";
import patients from "./modules/patients";
import referrals from "./modules/referrals";
import statuses from "./modules/statuses";
import user from "./modules/user";
import loader from "./modules/loader";
import results from "./modules/results";
import invoices from "./modules/invoices";
import referralGroups from "./modules/referralGroups ";
import features from "./modules/features";
import breadcrumbs from "./modules/breadcrumbs";
import createdinvoices from "./modules/createdinvoices";
import reports from "./modules/reports";
import adminPatients from "./modules/adminPatients";
import referralErrors from "./modules/referralErrors";
import adminDiscountCode from "./modules/adminDiscountCode";
import adminGuestUsers from "./modules/adminGuestUsers";
import adminCurators from "./modules/adminCurators";
import admins from "./modules/admins";
import gatekeepers from "./modules/gatekeepers";
import access from "./modules/access";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    language,
    languages,
    user,
    medicalCategories,
    auth,
    patients,
    facilities,
    modals,
    examinations,
    referrals,
    cart,
    doctors,
    statuses,
    message,
    billingData,
    loader,
    results,
    invoices,
    referralGroups,
    features,
    breadcrumbs,
    createdinvoices,
    reports,
    adminPatients,
    referralErrors,
    adminDiscountCode,
    adminGuestUsers,
    adminCurators,
    admins,
    gatekeepers,
    access,
  },
  plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
  strict: process.env.NODE_ENV !== "production",
});
