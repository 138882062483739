import http from "@/utils/axios";
import { notify } from "@kyvg/vue3-notification";
import router from "@/router/index.js";

export default {
  namespaced: true,
  state: {
    curators: [],
    errors: {},
    pagination: {
      currentPage: 1,
      total: 1,
    },
    curatorsCount: 0,
    search: "",
    currentCurator: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    },
  },
  getters: {
    getCurators: (state) => state.curators,
    getErrors: (state) => state.errors,
    getPagination: (state) => state.pagination,
    getSearch: (state) => state.search,
    getCurrentCurator: (state) => state.currentCurator,
    getCuratorById: (state) => (id) => {
      return state.curators.find((curator) => curator.id === id);
    },
    getCuratorsCount: (state) => state.curatorsCount,
  },
  mutations: {
    setCurators(state, curators) {
      state.curators = curators.data;
      state.pagination.currentPage = curators.meta.current_page;
      state.pagination.total = curators.meta.last_page;
    },
    setCuratorsCount(state, curatorsCount) {
      state.curatorsCount = curatorsCount;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    clearErrors(state) {
      state.errors = [];
    },
    setSearch(state, search) {
      state.search = search;
    },
    setCurrentPage(state, currentPage) {
      state.pagination.currentPage = currentPage;
    },
    setCurrentCurator(state, currentCurator) {
      state.currentCurator = currentCurator;
    },
    setCurrentCuratorValue(state, currentCurator) {
      Object.assign(state.currentCurator, currentCurator);
    },
    clearCurrentCurator(state) {
      Object.keys(state.currentCurator).forEach(
        (key) => (state.currentCurator[key] = "")
      );
    },
    updateCurator(state, user) {
      const index = state.curators.findIndex(
        (curator) => curator.id === user.id
      );
      state.curators[index] = user;
    },
    clearSearch(state) {
      state.search = "";
    },
    clearCurators(state) {
      state.curators = [];
      state.pagination.currentPage = 1;
      state.pagination.total = 1;
    },
  },
  actions: {
    async fetchCurators({ commit, state }, { filters, sortBy }) {
      const { data } = await http.get("admin/curators", {
        params: {
          page: state.pagination.currentPage,
          search: state.search,
          ...filters,
          ...sortBy,
        },
      });
      const curators = data.curators;
      commit("setCurators", curators);
      commit("setCuratorsCount", data.curators_count);
      commit("setCities", data.cities);
      commit("setMedicalCategories", data.medical_categories);
    },
    async changeStatus(_, data) {
      return await http
        .post(`/admin/curators/change-status/${data.id}`, {
          status: data.status,
        })
        .then(() => {
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 422)
            notify({
              type: "danger",
              text: "messages.save.failure",
              duration: 5000,
            });
        });
    },
    async saveCurator({ commit, state }) {
      return await http
        .post(`/admin/curators/store`, state.currentCurator)
        .then(() => {
          commit("clearErrors");
          commit("clearCurrentCurator");
          notify({
            type: "success",
            text: "messages.save.success",
            duration: 5000,
          });
          router.push({ name: "AdminCurators" });
        })
        .catch(({ response }) => {
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.save.failure",
              duration: 5000,
            });
          }
        });
    },
    async updateCurator({ commit, state }, id) {
      await http
        .put(`/admin/curators/update/${id}`, state.currentCurator)
        .then(() => {
          commit("clearErrors");
          notify({
            type: "success",
            text: "messages.edit.success",
            duration: 5000,
          });
          router.push({ name: "AdminCurators" });
          commit("clearCurrentCurator");
        })
        .catch(({ response }) => {
          if (response && response.status === 403) {
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
            return;
          }
          if (response && response.status === 422) {
            commit("setErrors", response.data.errors);
            notify({
              type: "danger",
              text: "messages.edit.failure",
              duration: 5000,
            });
          }
        });
    },
    async fetchCurrentCurator(
      { commit, rootGetters },
      { id, time = "current_month", date_range = null, filters, sortBy }
    ) {
      return await http
        .get(`admin/curators/${id}`, {
          params: {
            page: rootGetters["referrals/getPagination"].currentPage,
            time: time,
            date_range: date_range,
            ...filters,
            ...sortBy,
          },
        })
        .then(({ data }) => {
          commit("setCurrentCurator", data.curator);
          commit("setCurrentCuratorStats", data.stats);
          commit("referrals/setReferrals", data.referrals, { root: true });
          commit("referrals/setCommissionRange", data.commission_range, {
            root: true,
          });
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
    async editCurator({ commit }, id) {
      return await http
        .get(`admin/curators/edit/${id}`)
        .then(({ data }) => {
          commit("setCurrentCurator", data.curator);
        })
        .catch(({ response }) => {
          if (response && response.status === 403)
            notify({
              type: "danger",
              text: "messages.permission.failure",
              duration: 5000,
            });
        });
    },
  },
};
